<script lang="ts" setup>
import { useDisplay } from "vuetify";
import type { TSvgName } from "~/lib/svg";

const { sm, md, lg, xlAndUp } = useDisplay();
const svgHeight = computed(() => {
    if (xlAndUp.value) return "13rem";
    else if (lg.value) return "13rem";
    else if (md.value) return "12rem";
    else if (sm.value) return "11rem";
    else return "6rem";
});

const bookingStages: { text: string; svgName: TSvgName }[] = [
    {
        text: "Simply make a call to get all the information you need.",
        svgName: "phone-large"
    },
    {
        text: "Relax as our team takes care of everything, from your VIP airport pickup.",
        svgName: "airport"
    },
    {
        text: "To ensuring your surgery goes smoothly.",
        svgName: "surgery"
    },
    {
        text: "Leave with confidence, knowing you’ve received top-notch care.",
        svgName: "client-review"
    }
];
</script>
<template>
    <SectionWrapper>
        <h2 class="text-center mb-xl-10">
            <DecoratedTitle
                text="Booking surgery abroad has never been easier. Easy as 1… 2… 3…"
                decorated-phrase="Easy as 1… 2… 3…"
                append-icon="timer"
                append-icon-size=".7em"
                append-icon-position="120% -30%"
                append-icon-animation-class="tilt-n-move-shake"
                append-icon-animation-origin="center center"
                class="text-center mx-auto"
                style="max-width: 39ch"
            />
        </h2>
        <ClientOnly>
            <VRow no-gutters justify="center" class="my-5">
                <VCol cols="12" sm="10" md="8">
                    <VRow v-for="(item, i) in bookingStages" :key="i" justify="center">
                        <VCol cols="4" sm="5" :class="['text-center', { 'order-last': i % 2 }]">
                            <Svg :name="item.svgName" :height="svgHeight" width="100%"></Svg>
                        </VCol>
                        <VCol cols="6" class="d-flex align-center text-center text-body-2 text-sm-body-1 text-md-h6 font-weight-regular">
                            <p>{{ item.text }}</p>
                        </VCol>
                    </VRow>
                </VCol>
            </VRow>
        </ClientOnly>
        <h2 class="text-center">
            <DecoratedTitle
                text="We look forward to seeing you soon."
                class-name="text-body-1 text-sm-h6 text-md-h5 text-lg-h4"
                decorated-phrase="seeing you soon."
                append-icon="smiling-face-with-heart-eyes"
                append-icon-size=".8em"
                append-icon-position="120% 0%"
            />
        </h2>
    </SectionWrapper>
</template>
